<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="store" :query="{  }">
      <DefaultBtn v-show="validateActionsPage('store-add-warehouse')"  titleButton="Adicionar Produto" icon="add"  route="add-product-warehouse"/>
    </Mural>
    <main class="lesson-content q-my-lg">
      <div  class="content-table row flex q-pt-xs">
        <div class="column col-12">
            <Table
              class="full-height"
              :list="warehouses"
              :columns="columnsTable"    
              :addRemove="false" 
              :addEdit="validateActionsPage('store-edit-warehouse')"      
              @edit="(item) => edit(item)"
          ><template v-slot:body="props">
              <div>
                <q-btn
                  v-if="validateActionsPage('store-edit-providers')"
                  dense
                  round
                  color="default-pink"
                  title="Editar"
                  flat
                  @click="() => edit( props.props.row)"
                  icon="o_edit_note"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
    </main>
    <q-inner-loading
        :showing="loadingPage"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
    />
  </div>

</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import WareHouseService from '@/services/WareHouseService';
import Table from "@/components/shared/tables/TableDynamic.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";


import {ref, onMounted} from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
export default {
  name: "Warehouse",
  components: {
    Mural,
    DefaultBtn,
    Table,  
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Estoque"]);
    let warehouses = ref([]);
    let $q = new useQuasar();
    let router = new useRouter();
    let loadingPage = ref(false);
    let canUserAccessClass = new CanUserAccessClass();

    let columnsTable = ref([
      {name: 'id', align: 'left', label: 'ID', field: 'id', sortable: false},
      {
        name: 'name',
        required: true,
        label: 'Nome',
        align: 'left',
        field: row => row.name,
        sortable: true
      },
      {name: 'price', align: 'left', label: 'Preço', field: 'price', sortable: false},
      {name: 'quantity', align: 'left', label: 'Quantidade', field: 'quantity', sortable: false},
      {name: 'acao', align: 'center', label: 'Ações', field: 'acoes', sortable: false},
    ])

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function edit(product){
      router.push({ name: "add-product-warehouse", query: { productId: product.id } });
    }

    async function _getAllWarehouse(){
      loadingPage.value = true;
      let data = await _wareHouseService.getAllWarehouse();
      warehouses.value = data.data;
      loadingPage.value = false;
    }

    onMounted(() => {
      _getAllWarehouse();
    });

    return {
      breadcrumbs,
      warehouses,
      loadingPage,
      columnsTable,
      edit,
      validateActionsPage
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  Width: 100%;
  Height: 206px;
  background: #F7F7F7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.content {

  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #9C9C9C;

  .bold_number {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000 !important;
  }
}

.image-groups {
  border-radius: 20px;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
  width: 100px;
}
</style>